export default {
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  photo: null,
  surnameEn: null,
  surnameCh: null,
  subscribeToNewsletter: false,
  emailVerified: false,
  resourceUri: null,
  key: null,
  socialAccounts: null,
  isStaff: false,
  isShared: false,
  impersonatedUser: null,
  fwsUsername: null,
  isSubscribed: false,
  facebookConnectError: null,
  googleConnectError: null,
  familyTreeId: null,
  hintsTreeIds: {},
  isOnboarded: true, // to avoid showing the onboarding in case the refresh takes a long time
  onboardingStep: null,
  impersonatedUserFamilyTreeId: null,
  showFindOwnedTreeTooltip: null,
};
