import {PRICING_YEARLY} from '@/components/common/subscriptions/utils';

export default {
  subscriptionPlansState(state) {
    return state.plans;
  },
  standardYearlyPlanState(state) {
    return state.plans.find(plan => plan.billing_period === PRICING_YEARLY && plan.name.includes('Standard'));
  },
  isTrialEligibleState(state, getters) {
    return (
      state.subscriptions.length < 1 && getters.standardYearlyPlanState && getters.standardYearlyPlanState.trial_days
    );
  },
  subscriptionPlansLoadingState(state) {
    return state.plansLoading;
  },
  subscriptionsState(state) {
    return state.subscriptions;
  },
  subscriptionsLoadingState(state) {
    return state.subscriptionsLoading;
  },
};
