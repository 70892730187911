export default {
  setPlaceAlternativesState(state, data) {
    state.placeAlternatives = data;
  },
  setPlaceAlternativesLoadingState(state, loading) {
    state.placeAlternativesLoading = loading;
  },

  setPlaceSearchState(state, places) {
    state.placeSearch = places;
  },
  setPlaceSearchMetaState(state, meta) {
    state.placeSearchMeta = meta;
  },
  setPlaceSearchLoadingState(state, loading) {
    state.placeSearchLoading = loading;
  },
  setPlaceSearchStartTimeState(state, time) {
    state.placeSearchStartTime = time;
  },
  setPlaceSearchQueryState(state, query) {
    state.placeSearchQuery = query;
  },
  setPlaceSearchRootPlaceState(state, placeId) {
    state.placeSearchRootPlace = placeId;
  },
  setPlaceSearchMigrationPlaceState(state, place) {
    state.placeSearchMigrationPlace = place;
  },
  setPlaceSearchClanState(state, clan) {
    state.placeSearchClan = clan;
  },
  setPlaceSearchLevelState(state, levelInt) {
    state.placeSearchLevel = levelInt;
  },

  setPlaceState(state, place) {
    state.place = place;
  },
  setPlaceLocationState(state, location) {
    state.place = {...state.place, location: location};
  },
  setPlaceLoadingState(state, loading) {
    state.placeLoading = loading;
  },
  setPlaceDescendantsSearchState(state, places) {
    state.placeDescendantsSearch = places;
  },
  setPlaceDescendantsSearchMetaState(state, meta) {
    state.placeDescendantsSearchMeta = meta;
  },
  setPlaceDescendantsSearchLoadingState(state, loading) {
    state.placeDescendantsSearchLoading = loading;
  },
};
