export default {
  setSubscriptionPlansState(state, data) {
    state.plans = data;
  },
  setSubscriptionPlansLoadingState(state, data) {
    state.plansLoading = data;
  },
  setSubscriptionsState(state, data) {
    state.subscriptions = data;
  },
  setSubscriptionsLoadingState(state, data) {
    state.subscriptionsLoading = data;
  },
};
