export default {
  setCemeteryState(state, data) {
    state.details = data;
  },
  setCemeteryLoadingState(state, loading) {
    state.detailsLoading = loading;
  },

  setBurialLocationsState(state, data) {
    state.burialLocations = data;
  },
  setBurialLocationsLoadingState(state, loading) {
    state.burialLocationsLoading = loading;
  },
};
