import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  getPressArticleList: {
    url: 'pressarticle/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  getArticle: {
    url: 'wiki/article/{slug}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  getArticleRaw: {
    url: 'wiki/article/{slug}/raw/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  getCategory: {
    url: 'wiki/category/{slug}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  search: {
    url: 'wiki/search/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      q: [NetworkParamType.string],
      place_id: [NetworkParamType.string],
      fields: [NetworkParamType.string],
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
    },
  },
};
