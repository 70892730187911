import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  searchPlaces: {
    url: 'place/search/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      q: [NetworkParamType.string],

      clan: [NetworkParamType.string],
      clan_name: [NetworkParamType.string],
      level: [NetworkParamType.string],
      root: [NetworkParamType.array],
      is_qiao_xiang: [NetworkParamType.string],
      migration_place_id: [NetworkParamType.string],
      fields: [NetworkParamType.string],
    },
  },
  searchPlaceAlternatives: {
    url: 'place/search-alternatives/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    autoCancel: true,
    params: {
      q: [NetworkParamType.string],
      ancestral_places: [NetworkParamType.boolean],
      historical_places: [NetworkParamType.boolean],
    },
  },
  getCommonPlaceAlternatives: {
    url: 'place/common/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  getPlacesDetail: {
    url: 'place/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    restful: true,
    params: {
      ID: [NetworkParamType.string],
      fields: [NetworkParamType.string],
    },
  },
  searchPlaceSendEmail: {
    url: 'notify-request/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      email: [NetworkParamType.string],
      surname_place: [NetworkParamType.string],
    },
  },
  saveLocation: {
    url: 'place/{id}/save-location/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      location: [NetworkParamType.object],
    },
  },
};
