import network from '@common/network/network';
import upperFirst from 'lodash/upperFirst';

export default {
  searchClansAction(context, {q, limit, offset}) {
    context.commit('setClansSearchLoadingState', true);
    const startTime = Date.now();
    if (startTime < context.getters.clansSearchStartTimeState) {
      // in case multiple requests fire, make sure the latest results are displayed
      return;
    }
    context.commit('setClansSearchStartTimeState', startTime);
    return new Promise((resolve, reject) => {
      network.clan
        .searchClan({q, limit, offset})
        .then(response => {
          let objects = response.objects;
          if (startTime === context.getters.clansSearchStartTimeState) {
            context.commit('setClansSearchMetaState', response.meta);
            const alias = q ? upperFirst(q) : q;
            objects = response.objects.map(obj => ({...obj, alias}));
            context.commit('setClansSearchState', objects);
            context.commit('setClansSearchLoadingState', false);
          }
          resolve({...response, objects});
        })
        .catch(() => {
          context.commit('setClansSearchLoadingState', false);
        });
    });
  },
  fetchClansAction(context, {limit, offset, order_by}) {
    context.commit('setClansListLoadingState', true);
    return new Promise((resolve, reject) => {
      network.clan
        .getClansList({limit, offset, order_by})
        .then(response => {
          context.commit('setClansListState', response.objects);
          context.commit('setClansListMetaState', response.meta);
          resolve(response);
        })
        .finally(() => {
          context.commit('setClansListLoadingState', false);
        });
    });
  },
  getClanDetailsAction(context, character) {
    context.commit('setClanDetailedLoadingState', true);
    return new Promise((resolve, reject) => {
      network.clan
        .getDetailed({char: character})
        .then(response => {
          context.commit('setClanDetailedState', response);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit('setClanDetailedLoadingState', false);
        });
    });
  },
  getSpellingDetailsAction(context, spelling) {
    context.commit('setSpellingDetailedLoadingState', true);
    return new Promise((resolve, reject) => {
      spelling = spelling.trim();
      network.clanSpelling
        .getDetailed({spelling})
        .then(response => {
          context.commit('setSpellingDetailedState', response);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit('setSpellingDetailedLoadingState', false);
        });
    });
  },
  searchSpellingsAction(context, {place_id, limit}) {
    context.commit('setSpellingSearchLoadingState', true);
    return new Promise((resolve, reject) => {
      network.clanSpelling
        .search({place_id, limit, order_by: 'name'})
        .then(response => {
          context.commit('setSpellingSearchState', response.objects);
          context.commit('setSpellingSearchMetaState', response.meta);
          resolve(response);
        })
        .finally(() => {
          context.commit('setSpellingSearchLoadingState', false);
        });
    });
  },
};
