export default {
  fullPathBeforeLoginRegister: null,
  reviewedItems: [],
  notifications: [],
  dismissedNotifications: [],
  clanPedigreeDetailsPreviousRoute: {},
  previousRoute: {},
  onboardingWizard: {},
  onboardingWizardSaved: {},

  showCardQuickEditTooltip: false,
  showCardQuickViewTooltip: false,
  showCardFullProfileTooltip: false,
};
