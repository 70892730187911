import network from '@common/network/network';

export default {
  fetchWikiArticleAction(context, slug) {
    context.commit('setWikiArticleLoadingState', true);
    return new Promise((resolve, reject) => {
      network.wiki
        .getArticle({slug})
        .then(response => {
          context.commit('setWikiArticleState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setWikiArticleLoadingState', false);
        });
    });
  },
  fetchWikiCategoryAction(context, slug) {
    context.commit('setWikiCategoryLoadingState', true);
    return new Promise((resolve, reject) => {
      network.wiki
        .getCategory({slug})
        .then(response => {
          context.commit('setWikiCategoryState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setWikiCategoryLoadingState', false);
        });
    });
  },
  wikiSearchInBackgroundAction(context, {place_id, fields}) {
    const params = {
      place_id,
      fields,
      limit: 10,
      offset: 0,
    };
    return new Promise((resolve, reject) => {
      network.wiki.search(params).then(resolve).catch(reject);
    });
  },
  wikiSearchAction(context, {q, page}) {
    context.commit('setWikiSearchLoadingState', true);
    page = page || 1;
    const limit = 20;
    const offset = limit * (page - 1);
    const params = {
      limit,
      offset,
      q,
    };
    return new Promise((resolve, reject) => {
      network.wiki
        .search(params)
        .then(response => {
          context.commit('setWikiSearchState', response.objects);
          context.commit('setWikiSearchMetaState', response.meta);
          resolve();
        })
        .finally(() => {
          context.commit('setWikiSearchLoadingState', false);
        });
    });
  },
};
