import {NetworkAPIVersion, NetworkParamType} from '@common/network/network.utils';

export default {
  getSubscriptionPlans: {
    url: 'plan/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  getSubscriptions: {
    url: 'subscription/list/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  cancelSubscription: {
    url: 'subscription/{id}/cancel/',
    method: 'post',
    apiVersion: NetworkAPIVersion.v1_version,
  },
  createSubscriptionCheckoutSession: {
    url: 'subscription/start/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      plan_id: [NetworkParamType.string],
      success_url: [NetworkParamType.string],
      cancel_url: [NetworkParamType.string],
    },
  },
};
