export default {
  setFamilyTreePersonState(state, personData) {
    state.person = personData;
  },
  mutateFamilyTreePersonState(state, personData) {
    state.person = {...state.person, ...personData};
  },
  setFamilyTreePersonLoadingState(state, loading) {
    state.loading = loading;
  },
  setFamilyTreePersonFilesState(state, files) {
    state.personFiles = files.map(p => {
      return {...p, _id: p._id || p.object_id};
    });
  },
  setFamilyTreePersonFilesMetaState(state, meta) {
    state.personFilesMeta = meta;
  },
  setFamilyTreePersonFilesLoadingState(state, loading) {
    state.personFilesLoading = loading;
  },
  addToFamilyTreePersonFilesState(state, photo) {
    photo._id = photo._id || photo.object_id;
    let files = state.personFiles || [];
    files.splice(0, 0, photo);
    state.personFiles = [...files];
  },
  onUploadFamilyTreePersonFileState(state, photo) {
    let files = state.personFiles;
    for (let i = 0; i < files.length; i++) {
      if (files[i]._id === photo._id) {
        photo._id = photo.object_id;
        files[i] = photo;
        break;
      }
    }
    state.personFiles = [...files];
  },
  removeFromFamilyTreePeronFilesState(state, assetId) {
    let files = state.personFiles;
    if (files) {
      files = files.filter(e => e.object_id !== assetId);
      state.personFiles = [...files];
    }
  },
  setFamilyTreePersonRelativesState(state, data) {
    state.relativesByPersonId = {...state.relativesByPersonId, ...data};
  },
  mutatePersonFromFamilyTreePersonRelativesState(state, data) {
    let newRelativesByPersonId = {};
    const updateData = person => (person.object_id == data.object_id ? {...person, ...data} : person);
    for (let key of Object.keys(state.relativesByPersonId)) {
      let {parents, siblings, spouses, children, ...other} = state.relativesByPersonId[key];
      parents = parents.map(updateData);
      siblings = siblings.map(updateData);
      spouses = spouses.map(updateData);
      children = children.map(updateData);
      newRelativesByPersonId[key] = {parents, siblings, spouses, children, ...other};
    }
    state.relativesByPersonId = newRelativesByPersonId;
  },
  mutateMetaOfPersonRelativesState(state, data) {
    let newRelativesByPersonId = {};
    for (let key of Object.keys(state.relativesByPersonId)) {
      let {spouses_meta, ...other} = state.relativesByPersonId[key];
      let newSpousesMeta = {};
      for (let metaKey of Object.keys(spouses_meta)) {
        newSpousesMeta[metaKey] =
          data.couple_id === spouses_meta[metaKey].couple_id
            ? {...spouses_meta[metaKey], ...data}
            : spouses_meta[metaKey];
      }
      newRelativesByPersonId[key] = {spouses_meta: newSpousesMeta, ...other};
    }
    state.relativesByPersonId = newRelativesByPersonId;
  },
  setFamilyTreePersonRelativesLoadingState(state, loading) {
    state.relativesLoading = loading;
  },
  setFamilyTreeOptionsState(state, options) {
    state.options = options;
  },
  setFamilyTreeOptionsLoadingState(state, loading) {
    state.optionsLoading = loading;
  },
  setFamilyTreePersonUpdateLoadingState(state, loading) {
    state.updateLoading = loading;
  },
  setFamilyTreePersonFactsUpdateLoadingState(state, loading) {
    state.factsUpdateLoading = loading;
  },
  setFamilyTreePersonUpdateErrorsState(state, errors) {
    state.updateErrors = errors;
  },
  setFamilyTreePersonDeleteLoadingState(state, loading) {
    state.deleteLoading = loading;
  },
  setFamilyTreePersonDeleteErrorsState(state, errors) {
    state.deleteErrors = errors;
  },
  setFamilyTreePersonCreateLoadingState(state, loading) {
    state.createLoading = loading;
  },
  setFamilyTreePersonCreateErrorsState(state, errors) {
    state.createErrors = errors;
  },
  setFamilyTreePersonPhotosState(state, {photos, id}) {
    photos = photos.map(p => {
      return {...p, _id: p._id || p.object_id};
    });
    state.photosByPersonId = {...state.photosByPersonId, [id]: photos};
  },
  addToFamilyTreePersonPhotoState(state, {photo, id}) {
    photo._id = photo._id || photo.object_id;
    let photos = state.photosByPersonId[id] || [];
    photos.splice(0, 0, photo);
    state.photosByPersonId = {...state.photosByPersonId, [id]: photos};
  },
  onUploadFamilyTreePersonPhotoState(state, {photo, id}) {
    let photos = state.photosByPersonId[id];
    for (let i = 0; i < photos.length; i++) {
      if (photos[i]._id === photo._id) {
        photo._id = photo.object_id;
        photos[i] = photo;
        break;
      }
    }
    state.photosByPersonId = {...state.photosByPersonId, [id]: photos};
  },
  removeFromFamilyTreePeronPhotosState(state, {personId, assetId}) {
    let photos = state.photosByPersonId[personId];
    if (photos) {
      photos = photos.filter(e => e.object_id !== assetId);
      state.photosByPersonId = {...state.photosByPersonId, [personId]: photos};
    }
  },
  setFamilyTreePersonPhotosLoadingState(state, loading) {
    state.personPhotosLoading = loading;
  },
  setFamilyTreePersonProfilePictureLoadingState(state, loading) {
    state.profilePictureLoading = loading;
  },
  removeFamilyTreePersonProfilePictureState(state) {
    state.profilePictureLoading = loading;
  },
  setFamilyTreePersonDeleteAssetLoadingState(state, loading) {
    state.deleteAssetLoading = loading;
  },
  setUserPersonSurnamesState(state, surnames) {
    state.mySurnames = surnames;
  },
  setUserPersonSurnamesLoadingState(state, loading) {
    state.mySurnamesLoading = loading;
  },
  setPersonAcceptDataLoadingState(state, loading) {
    state.acceptDataLoading = loading;
  },
};
