import network from '@common/network/network';

export default {
  fetchSubscriptionPlansAction(context) {
    context.commit('setSubscriptionPlansLoadingState', true);
    return new Promise((resolve, reject) => {
      network.subscription
        .getSubscriptionPlans()
        .then(response => {
          context.commit('setSubscriptionPlansState', response.objects);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setSubscriptionPlansLoadingState', false);
        });
    });
  },
  fetchSubscriptionsAction(context) {
    context.commit('setSubscriptionsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.subscription
        .getSubscriptions()
        .then(response => {
          context.commit('setSubscriptionsState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setSubscriptionsLoadingState', false);
        });
    });
  },
  cancelSubscriptionAction(context, {id}) {
    return new Promise((resolve, reject) => {
      network.subscription
        .cancelSubscription({id})
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
  createSubscriptionCheckoutSessionAction(context, {plan_id, success_url, cancel_url}) {
    return new Promise((resolve, reject) => {
      network.subscription
        .createSubscriptionCheckoutSession({plan_id, success_url, cancel_url})
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
};
