export default {
  cemeteryState(state) {
    return state.details;
  },
  cemeteryLoadingState(state) {
    return state.detailsLoading;
  },
  burialLocationsState(state) {
    return state.burialLocations || [];
  },
  burialLocationsLoadingState(state) {
    return state.burialLocationsLoading;
  },
};
