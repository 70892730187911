export default {
  userUsernameState(state) {
    return state.username;
  },
  userFirstNameState(state) {
    return state.firstName;
  },
  userLastNameState(state) {
    return state.lastName;
  },
  userFullNameState(state) {
    return `${state.firstName} ${state.lastName}`;
  },
  userEmailState(state) {
    return state.email;
  },
  userPhotoState(state) {
    return state.photo;
  },
  userSurnameEnState(state) {
    return state.surnameEn;
  },
  userSurnameChState(state) {
    return state.surnameCh;
  },
  userSubscribeToNewsletterState(state) {
    return state.subscribeToNewsletter;
  },
  userEmailVerifiedState(state) {
    return state.emailVerified;
  },
  userFwsUsernameState(state) {
    return state.fwsUsername;
  },
  userResourceUriState(state) {
    return state.resourceUri;
  },
  userKeyState(state) {
    return state.key;
  },
  userIsLoggedInState(state) {
    return Boolean(state.key);
  },
  userIsSharedState(state) {
    return Boolean(state.isShared);
  },
  userIsSubscribedState(state) {
    return Boolean(state.isSubscribed);
  },
  socialAccountsState(state) {
    return state.socialAccounts;
  },
  facebookConnectErrorState(state) {
    return state.facebookConnectError;
  },
  googleConnectErrorState(state) {
    return state.googleConnectError;
  },
  userIsStaffState(state) {
    return state.isStaff;
  },
  impersonatedUserEmailState(state) {
    return state.impersonatedUser ? state.impersonatedUser.email : null;
  },
  userFamilyTreeIdState(state) {
    return state.impersonatedUser ? state.impersonatedUserFamilyTreeId : state.familyTreeId;
  },
  userHintsTreeIdState(state) {
    const treeIds = [...Object.keys(state.hintsTreeIds)].sort();
    return treeIds && treeIds[0];
  },
  userIsOnboardedState(state) {
    return state.isOnboarded;
  },
  userOnboardingStepState(state) {
    return state.onboardingStep;
  },
  showFindOwnedTreeTooltipState(state) {
    return state.showFindOwnedTreeTooltip;
  },
};
