import network from '@common/network/network';

export default {
  fetchSavedClanPedigreesAction(context, {page}) {
    const limit = 10;
    const offset = limit * (page - 1);
    context.commit('setSavedClanPedigreeListLoadingState', true);
    return new Promise((resolve, reject) => {
      network.savedPedigree
        .list({limit, offset})
        .then(response => {
          context.commit('setSavedClanPedigreeListMetaState', response.meta);
          context.commit('setSavedClanPedigreeListState', response.objects);
        })
        .finally(() => {
          context.commit('setSavedClanPedigreeListLoadingState', false);
        });
    });
  },
  createSavedClanPedigreeAction(context, {clanPedigreeId}) {
    context.commit('setSavedClanPedigreeCreateLoadingState', true);
    return new Promise((resolve, reject) => {
      network.savedPedigree
        .create({clan_pedigree_id: clanPedigreeId})
        .then(() => {
          context.commit('mutateClanPedigreeDetailsState', {is_user_saved: true});
          if (context.getters.sourceDetailsState.zupu_id === clanPedigreeId) {
            context.commit('mutateSourceDetailsState', {is_user_saved: true});
          }
          resolve();
        })
        .finally(() => {
          context.commit('setSavedClanPedigreeCreateLoadingState', false);
        });
    });
  },
  deleteSavedClanPedigreeAction(context, ownedClanPedigreeId) {
    context.commit('setSavedClanPedigreeDeleteLoadingState', true);
    return new Promise((resolve, reject) => {
      network.savedPedigree
        .delete({id: ownedClanPedigreeId})
        .then(() => {
          resolve();
        })
        .finally(() => {
          context.commit('setSavedClanPedigreeDeleteLoadingState', false);
        });
    });
  },
};
