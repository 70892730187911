export default {
  clansSearch: null,
  clansSearchMeta: {},
  clansSearchLoading: false,
  clansSearchStartTime: null,

  clansList: null,
  clansListMeta: {},
  clansListLoading: false,

  clanDetailed: null,
  clanDetailedLoading: false,
  spellingDetailed: null,
  spellingDetailedLoading: false,

  spellingSearch: null,
  spellingSearchMeta: {},
  spellingSearchLoading: null,
};
