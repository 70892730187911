import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  list: {
    url: 'saved-clan-pedigree/',
    method: 'get',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
    },
  },
  create: {
    url: 'saved-clan-pedigree/',
    method: 'post',
    apiVersion: NetworkAPIVersion.v1_version,
    params: {
      clan_pedigree_id: [NetworkParamType.string],
    },
  },
  delete: {
    url: 'saved-clan-pedigree/{id}/',
    method: 'delete',
    apiVersion: NetworkAPIVersion.v1_version,
  },
};
