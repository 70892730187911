import network from '@common/network/network';
import pickBy from 'lodash/pickBy';

export default {
  searchPlaceAlternativesAction(context, {query, onlyAncestralPlaces = false, includeHistoricalPlaces = false}) {
    context.commit('setPlaceAlternativesLoadingState', true);
    return new Promise((resolve, reject) => {
      const data = {q: query};
      if (onlyAncestralPlaces) {
        data.ancestral_places = true;
      }
      if (includeHistoricalPlaces) {
        data.historical_places = true;
      }
      network.place
        .searchPlaceAlternatives(data)
        .then(response => {
          context.commit('setPlaceAlternativesState', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setPlaceAlternativesLoadingState', false);
        });
    });
  },
  getCommonPlaceAlternativesAction(context) {
    context.commit('setPlaceAlternativesLoadingState', true);
    return new Promise((resolve, reject) => {
      network.place
        .getCommonPlaceAlternatives()
        .then(response => {
          context.commit('setPlaceAlternativesState', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setPlaceAlternativesLoadingState', false);
        });
    });
  },
  searchPlacesAction(context, {page, q, clan, clan_name, level, root, is_qiao_xiang, fields, migration_place_id}) {
    page = page || 1;
    const limit = 10;
    const offset = limit * (page - 1);
    let params = {limit, offset, q, clan, clan_name, level, root, is_qiao_xiang, fields, migration_place_id};
    params = pickBy(params, value => value === 0 || !!value);

    const startTime = Date.now();
    context.commit('setPlaceSearchLoadingState', true);
    return new Promise((resolve, reject) => {
      network.place
        .searchPlaces(params)
        .then(response => {
          if (startTime > context.getters.placeSearchStartTimeState) {
            context.commit('setPlaceSearchStartTimeState', startTime);
            context.commit('setPlaceSearchMetaState', response.meta);
            context.commit('setPlaceSearchState', response.objects);
          }
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setPlaceSearchLoadingState', false);
        });
    });
  },
  fetchPlaceDetailsAction(context, placeId) {
    context.commit('setPlaceLoadingState', true);
    return new Promise((resolve, reject) => {
      network.place
        .getPlacesDetail({id: placeId})
        .then(response => {
          context.commit('setPlaceState', response);
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit('setPlaceLoadingState', false);
        });
    });
  },
  searchPlaceDescendantsAction(context, {page, level, root, fields}) {
    page = page || 1;
    const limit = 10;
    const offset = limit * (page - 1);
    let params = {limit, offset, level, root, fields};

    context.commit('setPlaceDescendantsSearchLoadingState', true);
    return new Promise((resolve, reject) => {
      network.place
        .searchPlaces(params)
        .then(response => {
          context.commit('setPlaceDescendantsSearchMetaState', response.meta);
          context.commit('setPlaceDescendantsSearchState', response.objects);
          resolve(response);
        })
        .finally(() => {
          context.commit('setPlaceDescendantsSearchLoadingState', false);
        });
    });
  },
  savePlaceLocationAction(context, {id, location}) {
    return network.place.saveLocation({id, location});
  },
};
