export const publishStatuses = {
  ACTIVE: 'active',
  PENDING: 'pending',
  INVALID: 'invalid',
};

export const sourceTypes = {
  CEMETERY: 'cemetery',
  BURIAL_RECORD: 'burial_record',
  IMMIGRATION_CASE_FILES: 'immigration_case_files',
};

export const sourceTypeCategories = {
  CATEGORY_CENSUS: 'census-records',
  CATEGORY_IMMIGRATION: 'immigration',
  CATEGORY_BIRTH_DEATH: 'birth-marriage-death',
  CATEGORY_ZUPUS: 'zupus',
};
