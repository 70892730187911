export default {
  setSourcesTextSearchListState(state, list) {
    state.list = list;
  },
  setSourcesTextSearchMetaState(state, meta) {
    state.meta = meta;
  },
  setSourcesTextSearchLoadingState(state, loading) {
    state.loading = loading;
  },
  mutateSourcesTextSearchFormState(state, data) {
    console.log('mutateSourcesTextSearchFormState', data);
    state.form = {...state.form, ...data};
  },
  clearSourcesTextSearchFormState(state, data) {
    const form = {};
    for (let key of Object.keys(state.form)) {
      form[key] = data && data[key] ? data[key] : null;
    }
    state.form = form;
  },
  setSourcesTextSearchOptionsState(state, options) {
    state.options = options;
  },
  mutateSourcesTextSearchOptionsState(context, data) {
    const newOptions = {};
    for (let key of Object.keys(data)) {
      data[key].forEach(item => {
        newOptions[key] = context.options[key] || [];
        const index = newOptions[key].findIndex(facet => facet.object_id === item.object_id);
        if (index > -1) {
          newOptions[key].splice(index, 1 ,item);
        } else {
          newOptions[key].push(item);
        }
      })
    }

    context.options = {...context.options, ...newOptions};
  },
  setSourcesTextSearchOptionsLoadingState(state, loading) {
    state.loading = loading;
  },

  setSourcesTextSearchCountState(state, count) {
    state.count = count;
  },
  setSourcesTextSearchCountLoadingState(state, loading) {
    state.countLoading = loading;
  },
};
