export default {
  activeTreeId: null,
  detailsId: null,
  name: null,
  homePerson: null,
  mapImageHash: null,
  mapImage: null,
  mapImageScale: 0.04,
  mapImageLoading: false,
  isMiniMapVisible: null,
  refreshRequest: false,
  startPersonId: null,
  focusPersonId: null,
  drawLoading: null,
  isWriteAllowed: false,
  isUserOwner: false,
  isPublic: false,
  treeMembers: [],
  preferences: {},

  isLangChinese: false,

  treeWidth: null,
  treeHeight: null,
  personsDrawn: null,
  linesDrawn: null,
  treeMarginX: 0,

  list: null,
  listLoading: null,
  updateLoadingById: {},

  personsSearchList: [],
  personsSearchListMeta: null,
  personsSearchListLoading: null,

  mostTaggedLocations: [],
  mostTaggedLocationsLoading: null,

  surnames: [],
  surnamesMeta: {},
  surnamesLoading: null,

  personsBySurname: {},
  personsBySurnameLoading: null,

  details: null,
  detailsLoading: null,

  treeAddMembersLoading: false,
  treeEditMemberLoading: {},
  treeDeleteMemberLoading: {},
};
