export default {
  article: null,
  articleLoading: false,
  category: null,
  categoryLoading: false,
  search: null,
  searchMeta: null,
  searchLoading: null,
  searchFormQuery: null,
};
