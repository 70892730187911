import network from '@common/network/network';

export default {
  fetchOwnedClanPedigreesAction(context, {page}) {
    const limit = 10;
    const offset = limit * (page - 1);
    context.commit('setOwnedClanPedigreeListLoadingState', true);
    return new Promise((resolve, reject) => {
      network.ownedPedigree
        .list({limit, offset})
        .then(response => {
          context.commit('setOwnedClanPedigreeListMetaState', response.meta);
          context.commit('setOwnedClanPedigreeListState', response.objects);
        })
        .finally(() => {
          context.commit('setOwnedClanPedigreeListLoadingState', false);
        });
    });
  },
  startBuyClanPedigreeAction(context, {zupuId}) {
    context.commit('setBuyZupuLoadingState', true);
    return new Promise((resolve, reject) => {
      network.pedigree
        .requestClanPedigreeBuy({id: zupuId})
        .then(response => {
          resolve(response.session_id);
        })
        .catch(() => {
          context.commit('setBuyZupuLoadingState', false);
          reject();
        });
    });
  },
};
