import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  searchClan: {
    url: 'clan/search/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      q: [NetworkParamType.string],
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
    },
  },
  searchClanRegion: {
    url: 'clan/search-region/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      q: [NetworkParamType.string],
      country_region_id: [NetworkParamType.string],
      dialect_region_id: [NetworkParamType.string],
    },
  },

  get: {
    url: 'clan/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    restful: true,
    params: {
      ID: [NetworkParamType.string],
    },
  },
  getDetailed: {
    url: 'clan/{char}/details/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  clanSearchPreview: {
    url: 'clan/?order_by=-priority&limit=15',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  getClansList: {
    url: 'clan/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      limit: [NetworkParamType.number],
      offset: [NetworkParamType.number],
      order_by: [NetworkParamType.string],
    },
  },
  getSurnameExists: {
    url: 'clan/surname/{surname}/exists/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
};
